import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Margin from "../components/margin"
import Leading from "../components/leading"
import SectionLine from "../components/section-line"


class RewardPage extends React.Component {

  delgs = [];

  delgs1 = [
    [ 335, "stake1u9mnywlyey8mpudsq6w0yedplz2djek0cnl9s7s35ypsypgnte9ae",    6, "00fa7e73bb9606481830ff2e1c9f77cbb8ec901e0dc79ef9ea97fc6e5cc201b0" ],
    [ 335, "stake1u8vmh5a9td53wzm5gw7daprsal8dxauy27yks3d6k09ng0gq4me9r",   23, "2a26c1ceb17ffb2b5a360791ebd5b1b32d9f35a47adb1b4b3c55d241633ed745" ],
    [ 336, "stake1u95me5dqshq6gre7fgm3q8naszwfz2xx9ahlyqjhqkp5wzqqacfmj",   21, "8199641ec1d02f6b431ca54825c9d72f381908db9c59e3a1c6ff85b29cf87af7" ],
    [ 337, "stake1u8l9y6zun2znqzz8ydz2wcfqamazttqj7u47ckma6dy4skgaakh8c",    1, "f03c616bad16a28b96373997543b5ff1d355b85e8e52e9ba416272cb1a74c2a6" ],
    [ 339, "stake1uyyu5njqacjn6vfknqqt50xz55lju6vlznepdt8pd8xls2qytx5v4",   12, "8bc78e9948b0f37132863e21957c13e3dba3c583b78c8d3c6c859a67ab2ef0ee" ],
    [ 339, "stake1uxmnf5l6ykjvp7ztmue0sgkqsks598p8khyj825g2emwx2g34zp78",    3, "a93c9849a7ec4b2d648bb069215f781eecc4b63a711bfe5938e86dd27b79874f" ],
    [ 339, "stake1u9p445t2sk3rmzs9mgtjawzf2zm38rhlr553t8c96lpx56qztnv9v",    2, "ff66b010c364fa6598a56bf2793d5e5585f858529a73465f6b04e1d9c84f71d8" ],
    [ 345, "stake1u9mnywlyey8mpudsq6w0yedplz2djek0cnl9s7s35ypsypgnte9ae",    6, "414cb8f86b43f7803cc7fc762b092ec0186d2eb425b00710f2252065ae6fbaaf" ],
    [ 345, "stake1u8vmh5a9td53wzm5gw7daprsal8dxauy27yks3d6k09ng0gq4me9r",   23, "8dfe02817099f9176f7bdc422f92c7d3f8792c0fa0d8402595710811d2eae457" ],
    [ 346, "stake1u95me5dqshq6gre7fgm3q8naszwfz2xx9ahlyqjhqkp5wzqqacfmj",   21, "bb78d5ea0203f0ba2fc0ec8227538253be5a1a46d62d6031592d797ae323a13a" ],
    [ 347, "stake1u8l9y6zun2znqzz8ydz2wcfqamazttqj7u47ckma6dy4skgaakh8c",    1, "f1f40979712d9dfd1f1a2727d5f5e76a718a801cce5fb9452c19d608350fad52" ],
    [ 348, "stake1u8fh7qtvrzkne7uxk6g797eqxskfwr9p0kt9sh96yqymfrszvdd7c",    2, "f92b1d0c29028f8fe4cf74a38b104bb645d19a92c7863c57bf284c34ca1f17b8" ],
    [ 355, "stake1uy0tfaphr34tz4yv04p5x0a023jk60cw9vw8tl043mlrcwq4pv3l7",  724, "20734f0d626d93e3f775ec204d1bed450f0220f192e624133dcc6603e2d6c421" ],
    [ 356, "stake1u95me5dqshq6gre7fgm3q8naszwfz2xx9ahlyqjhqkp5wzqqacfmj",   21, "0f3622c9f458f8bdb6bb6d2272ed4d9b56323234945c7a05730637be446f42ff" ],
    [ 356, "stake1u9p9plzxxg0lmjlqea3pvf5gzuvtcjwvr5sevqxtcr88u4gahm4lx",   10, "dcce9b57cee9649b401873d38db9149c0d0f982e33b6f1b1f2e346f9532db2b6" ],
    [ 356, "stake1u92vzr2zd62dy82amd7tzhzpvxlgv8wl5laljc7jqgc8p5cnrtgla",    1, "9e05221195d56a7a1584be149cb4d906aca7ea3d6dfdda43e243ff0dc73b0201" ],
    [ 358, "stake1u8fh7qtvrzkne7uxk6g797eqxskfwr9p0kt9sh96yqymfrszvdd7c",    3, "25f0d05d83bf7bbf9a8634a73b8de594b8cd7147640b2285f0f05eb2749f3b30" ],
    [ 358, "stake1u87j5z52whcxk6su7j5swsyucvzaujsxnxxwn98f4hg493q93s302",   18, "0f80074ea523018dc20d2e73ada8a79a9fd184109bcd85a07b137e00bd230c26" ],
    [ 360, "stake1u8fr2xq9aur683rpkumt5j6l5drz89hk3uuwu9qxk44n32gnrl6dk",   10, "62f7148e705ef02debb8d7a8d274ae8adc4d0304825dacb5e6e30778ee7b8a1c" ],
    [ 362, "stake1u9v0wk8plzg25c0xjwzu07ljgsaznkwp8xzdtmz8sknnwlcezvrv9",  387, "6820de312362184a3145135e40aca8b8a6ca24657847df98a14f3b25d7da161d" ],
    [ 365, "stake1uy0tfaphr34tz4yv04p5x0a023jk60cw9vw8tl043mlrcwq4pv3l7",  276, "40a305264b81e09ff3e7f097904fcf43d8cb42462803b1ac941ad3e375f8bc37" ],
    [ 366, "stake1u95me5dqshq6gre7fgm3q8naszwfz2xx9ahlyqjhqkp5wzqqacfmj",   22, "33b52a16a4ed26d0c4c85ec1f875e8cc33cab751bbc5a41f993c44dc35246599" ],
    [ 366, "stake1u9p9plzxxg0lmjlqea3pvf5gzuvtcjwvr5sevqxtcr88u4gahm4lx",   13, "e81bc1b851f91ff6dee0e7da309f3d6ef8459f54c8cfa213945b8179f55f2ef7" ],
    [ 366, "stake1u92vzr2zd62dy82amd7tzhzpvxlgv8wl5laljc7jqgc8p5cnrtgla",    1, "c36eadf49b3bcf5fbddfa45c1e63834b48002ca588e7757348df088abe0ca30e" ],
    [ 368, "stake1u8fh7qtvrzkne7uxk6g797eqxskfwr9p0kt9sh96yqymfrszvdd7c",    3, "e276cbfdbf66261e39c26acf3940a5127cbcdab09693feb82373c3f62fd4394b" ],
    [ 368, "stake1u87j5z52whcxk6su7j5swsyucvzaujsxnxxwn98f4hg493q93s302",   19, "4aa62b0b462e802686de0621125ac3eabc0735bdc7235d27866316abd8a92dcc" ],
    [ 370, "stake1u8fr2xq9aur683rpkumt5j6l5drz89hk3uuwu9qxk44n32gnrl6dk",   10, "cf17bb5f2883d391d63c878fbe0a1113cd8b76f7dbfb824290dfcd270e8e192b" ],
    [ 370, "stake1uxyummagz5up6d4dnhw7xqpj27feetngrdcqgc2te9zs8nsz68yd8",   51, "8c3d5c83bb3abffdbcf1ea5d93817313f31201885dc21c00fd053d4b9db9ac04" ],
    [ 372, "stake1u9v0wk8plzg25c0xjwzu07ljgsaznkwp8xzdtmz8sknnwlcezvrv9",  390, "e6525d85cc6221bc39664f36fc05d724bcd8ff0e30c108a18f562c82dd1a4d51" ],
    [ 376, "stake1u95me5dqshq6gre7fgm3q8naszwfz2xx9ahlyqjhqkp5wzqqacfmj",   22, "722dad5d9a4e02cdc9fb347718a7007c871d85a9c1c2f736359835534be93a25" ],
    [ 376, "stake1u92vzr2zd62dy82amd7tzhzpvxlgv8wl5laljc7jqgc8p5cnrtgla",    1, "83c73d4ec473aa70fe3a6fdb244e01d65357d5888a382cd16be72068d7823ab0" ],
    [ 378, "stake1u8fh7qtvrzkne7uxk6g797eqxskfwr9p0kt9sh96yqymfrszvdd7c",    3, "ace58c6bf8fc6c03829f642d5c3b5a223834854d736e08c629f94c2724283270" ],
    [ 378, "stake1u87j5z52whcxk6su7j5swsyucvzaujsxnxxwn98f4hg493q93s302",   20, "c6deddf5a5a0545818457c4cfbe091480307ab3c0f22a0165747b0d616b1b416" ],
    [ 380, "stake1u8fr2xq9aur683rpkumt5j6l5drz89hk3uuwu9qxk44n32gnrl6dk",   10, "8c87c19825ab1f4287b4885ab77b7a937d7e617be658e6da11cb2b87803ebabc" ],
    [ 380, "stake1uxyummagz5up6d4dnhw7xqpj27feetngrdcqgc2te9zs8nsz68yd8",   55, "4251814146ee64125ba9c19833a12fe792521878728688fabd539740d5288aa3" ],
    [ 381, "stake1uyvuxt2s7jffsaj9pghq2e5jdzajsxm502c6qq2mp8e9fscwn3qvl",  247, "5faa36a74281037acc34e62af9b48b0db07d46a541177903a2bd41a1729496da" ],
    [ 382, "stake1u9v0wk8plzg25c0xjwzu07ljgsaznkwp8xzdtmz8sknnwlcezvrv9",  223, "1d125ae8b6b49ab477aa28cc2ff2c8430ae0cfdd9d12a3995d5ac3c0f3a9e71e" ],
    [ 385, "stake1uxlmjk229qalmwqauzge5hx3e7cpm8hed845azsjcay6k8g20vual",    7, "6effc1c38e294787f591186fc662f8ad91e7db58a703518a32c039d6c59d4c3c" ],
    [ 385, "stake1uycdec67epv48vnpckjwtjdjsqnacg6ht2c2e6m96nt8mzsdfvyx2", 1000, "70c2868819e572604162ed3a6d291cb1348803b6be560906075c0aa010aa2c0d" ],
    [ 386, "stake1u95me5dqshq6gre7fgm3q8naszwfz2xx9ahlyqjhqkp5wzqqacfmj",   22, "393a8668912b69b9d8e8fa71364c1a76c0ac22eeb0153235370b0da4a2a98562" ],
    [ 386, "stake1u92vzr2zd62dy82amd7tzhzpvxlgv8wl5laljc7jqgc8p5cnrtgla",    1, "fc83cdca528a2fd4629ac552db5fbd80212895b92a0ca86b3a8da887af55a7ba" ],
    [ 388, "stake1u8fh7qtvrzkne7uxk6g797eqxskfwr9p0kt9sh96yqymfrszvdd7c",    3, "404953c1e3dd56f0ada0a797f87548542bd71a346694868f38ef78830b19bb14" ],
    [ 388, "stake1u87j5z52whcxk6su7j5swsyucvzaujsxnxxwn98f4hg493q93s302",   20, "c134963aaa7cba974c0ac1f745ad37a6162053bef9b91aaf22f2e681d5a6fbbe" ],
    [ 388, "stake1u9cf4dwhv6me3vazs387etvpyxygzh9x3dez8guxf2xa26glh3wpq",    5, "a3d134cb3b11acc9b7943684bd135862070ff325a09df717bcca00c6e2559eab" ],
    [ 389, "stake1uy7tct5ur3qc23gxs6j2346z8qasf8sd0dq4trk2rtnyj8gxp96gl",  181, "62ebd68d6b8874ceb834dcae2474ba8a517e2434fdf4ec4883da72f1cd6b4fd3" ],
  ];
  remain = 3900;

  constructor() {
    super( {} );
    this.state = {
      filteredDelgs: this.delgs,
      filteredDelgs1: this.delgs1,
    };
    this.handleChange = this.handleChange.bind(this);
    const to_amount = function ( elm : any ) {
      return elm[2];
    };
    let amount_sum = 0;
    for ( let i = 0 ; i < this.delgs1.length ; i++ ) {
      amount_sum += ( this.delgs1[i][2] as number );
    }
    this.remain = this.remain - amount_sum;
  }

  handleChange = ( event: React.ChangeEvent<HTMLInputElement> ) => {
    const text = event.target.value;

    const filter = function ( elm : any ) {
      const addr : string = elm[0];
      if ( addr.indexOf( text ) === 0 ) return true;
      else return false;
    };

    const filter1 = function ( elm : any ) {
      const addr : string = elm[1];
      if ( addr.indexOf( text ) === 0 ) return true;
      else return false;
    };

    let filtered = this.delgs;
    let filtered1 = this.delgs1;
    if ( text.length > 0 ) {
      filtered = filtered.filter( filter );
      filtered1 = filtered1.filter( filter1 );
    }
    this.setState(
      {
        filteredDelgs: filtered,
        filteredDelgs1: filtered1
      }
    );
  };

  render() {
    const { filteredDelgs, filteredDelgs1, remain } = this.state;
    const rows = filteredDelgs.map( ( delg : any, index : any ) =>
      <tr key={delg[0]}>
        <td style={{ textAlign: "left", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{delg[0].substr( 0, 15 )}...</td>
        <td></td>
        <td style={{ textAlign: "right", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{delg[1].toLocaleString()}&thinsp;ADA</td>
        <td></td>
        <td style={{ textAlign: "right", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{delg[2].toLocaleString()}&thinsp;ADA</td>
        <td></td>
        <td style={{ textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{delg[3]} / 10</td>
      </tr>
    );
    const rows1 = filteredDelgs1.reverse().map( ( delg : any, index : any ) =>
      <tr key={delg[0]}>
        <td style={{ textAlign: "right", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{delg[0]}</td>
        <td></td>
        <td style={{ textAlign: "left", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{delg[1].substr( 0, 15 )}...</td>
        <td></td>
        <td style={{ textAlign: "right", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>{delg[2].toLocaleString()}&thinsp;ADA</td>
        <td></td>
        <td style={{ textAlign: "left", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>
          <a href={"https://adapools.org/transactions/" +  delg[3]} target="_blank">
           {delg[3].substr( 0, 15 )}...
          </a>
        </td>
      </tr>
    );

    return (
      <Layout>
        <Seo title="Reward" />

        <div className="container">
          <Leading main="継続委任キャンペーン" sub="報酬確認"/>

          <div className="box" style={{ maxWidth: '700px' }}>
            <div className="container-text">
              &middot;このキャンペーンは終了いたしました。<br />
              &middot;PCからの閲覧または端末を横長表示にしての閲覧を推奨いたします。<br />
            </div>
          </div>

          <Margin height="1rem"/>

          <div style={{ background: "navy", color: "white", borderRadius: "1rem", maxWidth: "30rem", padding: "0.5rem", margin: "auto", fontSize: "110%", fontWeight: "bold", textAlign: "center" }}>
            キャンペーン報酬残額<br />
            <span className="mono">{this.remain.toLocaleString()}</span>&thinsp;/&thinsp;<span className="mono">3,900</span>&thinsp;<span className="mono">ADA</span>
          </div>

          <Margin height="1rem"/>

          <div style={{ width: "100%", maxWidth: "24rem", margin: "auto" }}>
            <input type="text" className="mono-or-sans" onChange={this.handleChange} placeholder={"アドレスで検索"} style={{ width: '100%', maxWidth: '400px' }}/>
          </div>

          <Margin height="1rem"/>

          <strong>配布予定</strong>
          <Margin height="0.5rem"/>
          <div style={{ background: 'ghostwhite', margin: 'auto', width: "100%", maxWidth: "800px", overflowX: "scroll", padding: "0.5rem", borderRadius: '10px' }}>
            <table style={{ textAlign: "left", minWidth: "560px", maxWidth: "800px", padding: "2rem", margin: 0 }}>
              <thead style={{ padding: "1rem", borderBottom: "1px solid slategray" }}>
                <tr>
                  <td style={{ width: "25%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>アドレス</td>
                  <td style={{ width: "2%" }}></td>
                  <td style={{ width: "23%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>平均委任額</td>
                  <td style={{ width: "2%" }}></td>
                  <td style={{ width: "23%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>予定報酬額</td>
                  <td style={{ width: "2%" }}></td>
                  <td style={{ width: "23%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>経過エポック</td>
                </tr>
              </thead>
              <tbody className="mono" style={{ textAlign: "right" }}>
                {rows}
              </tbody>
            </table>
          </div>

          <Margin height="1rem"/>

          <strong>配布済み</strong>
          <Margin height="0.5rem"/>
          <div style={{ background: 'ghostwhite', margin: 'auto', width: "100%", maxWidth: "800px", overflowX: "scroll", padding: "0.5rem", borderRadius: '10px' }}>
            <table style={{ textAlign: "left", minWidth: "560px", maxWidth: "800px", padding: "2rem", margin: 0 }}>
              <thead style={{ padding: "1rem", borderBottom: "1px solid slategray" }}>
                <tr>
                  <td style={{ width: "12%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>エポック</td>
                  <td style={{ width: "4%" }}></td>
                  <td style={{ width: "22%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>アドレス</td>
                  <td style={{ width: "4%" }}></td>
                  <td style={{ width: "12%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>報酬額</td>
                  <td style={{ width: "4%" }}></td>
                  <td style={{ width: "42%", textAlign: "center", whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>TX</td>
                </tr>
              </thead>
              <tbody className="mono" style={{ textAlign: "right" }}>
                {rows1}
              </tbody>
            </table>
          </div>

        </div>

        <Margin height="0.5rem"/>
        <SectionLine />

        <div className="container">
          <p>
            <Link to="/campaign" style={{ display: "inline" }}>
              <strong>キャンペーン概要</strong>
            </Link>
          </p>
          <p>
            <Link to="/" style={{ display: "inline" }}>
              <strong>トップページ - Top Page</strong>
            </Link>
          </p>
        </div>
      </Layout>
    )
  }
}

export default RewardPage
